/**
 * filename: bsl.js
 *
 * This is the main script for the BSL website
 */

import '@fontsource-variable/roboto-flex';
import '@fontsource-variable/noto-sans-hk';
import '../scss/bsl.scss';
import '../css/bsl.css';
import * as bootstrap from 'bootstrap';

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
const backToTopBtn = document.getElementById('backToTopBtn');
const scrollPane = document.querySelector("div.overflow-y-auto[name='scroll-pane']");
const publicationSelectFilter = document.getElementById('publicationSelectFilter');
const tutorialSelectFilter = document.getElementById('tutorialSelectFilter');

/**
 * function to show the scroll button when the height is over 20px
 */
function showScrollBtn() {
  const scrollBound = 20;
  if ( document.body.scrollTop > scrollBound ||
    document.documentElement.scrollTop > scrollBound ) {
    backToTopBtn.style.display = 'block';
  } else {
    backToTopBtn.style.display = 'none';
  }
}


/**
 * function to scroll back to the top
 */
function scrollToTop() {
  document.body.scrollTo({ top: 0, behavior: 'smooth' });
  document.documentElement.scrollTop = 0;
  if ( scrollPane ) {
    scrollPane.scrollTo({ top: 0, behavior: 'smooth' });
  }
  document.querySelector(".active[name='subtext']")?.scrollIntoView();
}


/**
 * function to random sort nodes under a parent element
 * Sorting is done on the frontend to minimize backend loading
 */
function randomSort(parentNode){
  for (let i = parentNode.children.length; i >= 0; i--) {
      parentNode.appendChild(parentNode.children[Math.random() * i | 0]);
  }
}


/**
 * Function to scroll progress
 */
function scrollProgressBar(element) {
  function getMax() {
    return element.scrollHeight - element.clientHeight;
  };
  
  function getValue() {
    return element.scrollTop;
  };
  
  let progressBar = document.querySelector(".progress-bar");
  let max = getMax();
  let value;
  let width;
  
  // Function to calculate the width in percentage
  function getWidth() {
    value = getValue();
    width = (value / max) * 100;
    width = width + "%";
    return width;
  };
  
  // Function to set the width of the progress bar
  function setWidth() {
    progressBar.style.width = getWidth();
  };
  
  element?.addEventListener('scroll', setWidth);
  window.addEventListener('resize', () => {
    // Need to reset max
    max = getMax();
    setWidth();
  })
}


/**
 * On content load, randomize material entries
 */
document.addEventListener('DOMContentLoaded', () => {
  let gallerySelect = document.getElementById('gallerySelect');
  if ( gallerySelect ) {
    randomSort(gallerySelect);
  }

  window.addEventListener('scroll', showScrollBtn);
  backToTopBtn?.addEventListener('click', scrollToTop);
  document.querySelector(".active[name='subtext']")?.scrollIntoView();
  if ( scrollPane ) {
    scrollProgressBar(scrollPane);
  }

  if ( publicationSelectFilter ) {
    const savedValue = localStorage.getItem('publicationSelectFilter');
    if (savedValue) {
      publicationSelectFilter.value = savedValue;
    }

    publicationSelectFilter.addEventListener('change', function() {
      let value = publicationSelectFilter.value;
      let url = `${window.location.protocol}//${window.location.host}/publications/`;
      localStorage.setItem('publicationSelectFilter', value); // the tab value is updated
      if ( value.length === 0 ) {
        window.location.href = url;
      } else {
        window.location.href = url + value + '/';
      }
    });
  }

  if ( tutorialSelectFilter ) {
    const savedValue = localStorage.getItem('tutorialSelectFilter');
    if (savedValue) {
      tutorialSelectFilter.value = savedValue;
    }

    tutorialSelectFilter.addEventListener('change', function() {
      let value = tutorialSelectFilter.value;
      let url = `${window.location.protocol}//${window.location.host}/tutorials/`;
      localStorage.setItem('tutorialSelectFilter', value); // the tab value is updated
      if ( value.length === 0 ) {
        window.location.href = url;
      } else {
        window.location.href = url + 'chapter/' + value + '/';
      }
    });
  }
})

